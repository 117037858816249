.side-drawer{
    height: 100%;
    background: #fff;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position:fixed;
    top:0;
    right:0;
    width: 70%;
    max-width: 300px;
    z-index:200;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open{
    transform: translateX(0);
}

.sidebar-section{
    padding: 15px;
}