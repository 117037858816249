.image-holder{
    display: flex;
}

.static-image{
    width: 320px;
    height: 240px;
    margin: auto;
}

.separator{
    margin:auto;
    display: flex;
    width:96px;
    height:96px;
}