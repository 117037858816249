.toolbar{
    position: fixed;
    width: 100%;
    background-color: #ddd;
    height: 56px;
    top: 0;
    left: 0;
    z-index: 100;
}

.toolbar__logo a {
 color: black;
 text-decoration: none;
 font-size: 1.5rem;
}

.spacer{
    flex: 1;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding : 0 1rem;
}

.toolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 0 0.5rem;
}

.toolbar_navigation-items a {
    color: black;
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active{
    color: #fa923f;
}

.affiliate-disclosure{
    text-align: center;
    background-color: #eee;
}