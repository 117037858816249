.toggle-button{
    height: 24px;
    width: 32px;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
}

.toggle-button:focus{
    outline: none;
}

.toggle-button__line{
    width: 32px;
    height: 2px;
    background: black;
}