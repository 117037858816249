.social-section{
    display:flex;
    padding: 20px 0px 0px 50px;
}

.social-section span{
    flex: 1;
}

.social-button {
    width: 40px;
    height: 40px;
    background-size: 40px;
    display: inline-block; 
  }

  .twitter {
    background-image: url("./icons/Twitter_Social_Icon_Rounded_Square_White.png");
  }

  .instagram {
    background-image: url("./icons/glyph-logo_May2016.png");
  }

  .pinterest {
    background-image: url("./icons/badgeWHITE.png");
  }